<template>
<div>

  <v-card>
    <v-card-title>
      {{titulo}}
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="fas fa-search"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

    <v-container>
        <v-row>
          
          <v-col cols="12" sm="12" md="4">
            <v-autocomplete
              v-model="id_customer"
              :items="customers"
              item-text="nombre"
              item-value="codigo"
              label="Escoge un Cliente"
              @change="getByCustomer"
              clearable
              rounded
              solo
              hide-details
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="12" md="7">
          <v-row>

          <v-col cols="12" sm="12" md="4">
            <v-btn
              color="success"
              class="ma-2 white--text"
              @click="showModal"
              block
            >
              Calcular <v-icon right >
                fas fa-calculator
              </v-icon>
            </v-btn>
          </v-col>
          <!-- <v-col cols="12" sm="12" md="4">
            <v-btn
              :loading="loading3"
              :disabled="loading3"
              color="warning"
              class="ma-2 white--text"
              @click="getAll"
              block
            >
              Ver Todo
              <v-icon right >
                fas fa-eye
              </v-icon>
            </v-btn>
          </v-col> -->
          </v-row>

          </v-col>
          <v-col cols="12" sm="12" md="1">

            <v-tooltip top color="purple">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="purple"
                  @click="getSettings"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon dark>
                    fas fa-cog
                  </v-icon>
                </v-btn>
              </template>
              <span>Factores de Conversión</span>
            </v-tooltip>

          </v-col>
         

        </v-row>
    </v-container>

      <!-- :items="currentItems" -->
    <v-data-table
      v-model="editedItem.selected"
      :headers="headers"
      :items="currentItems"
      :search="search"
      sort-by="id"
      sort-desc
      class="elevation-1"
      :loading="loading"
      loading-text="Consultando datos... Espere por favor"
      show-select
      checkbox-color="success"
      item-key="coddoc"
      
    >
      <template v-slot:[`item.calc_ret`]="{ item }">
          <div>
            <!-- {{ parseFloat(item.precio * item.cant).toFixed(2) }} -->
            <!-- {{ numberWithCommas((item.precio * item.cant).toFixed(2)) }} -->
            {{ calc_ret(item.cant, item.id_coffee) }}
          </div>
      </template>
      <template v-slot:top>
          <v-dialog v-model="dialog" max-width="1200px">
            <!-- <template v-slot:activator="{ on }">
                <v-btn ref="boton2" color="primary" dark v-on="on">Nuevo Registro</v-btn>
            </template> -->
            <v-card>
              <v-card-title>
                <span class="headline">Retenciones a Pagar</span>
                <v-spacer></v-spacer>
                <v-radio-group
                 row
               >
                 <v-switch
                    v-model="editedItem.invalid"
                    color="red"
                    inset
                    label="Comprobante no válido para constancias"
                    class="mr-4"
                  ></v-switch>

               </v-radio-group>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-form ref="form" v-model="valid" autocomplete="off">
                    <v-row>
                      <v-col cols="12" sm="12" md="6">
                        <v-autocomplete
                          v-model="editedItem.productor"
                          :items="customers"
                          item-text="nombre"
                          item-value="codigo"
                          label="Escoge un Cliente"
                          :rules="editedItem.id_productorRules"
                          clearable
                          rounded
                          solo
                          return-object
                        ></v-autocomplete>
                      </v-col>

                      <v-col cols="12" sm="12" md="6">
                        <v-text-field
                            v-model="editedItem.comment"
                            label="Número de comprobante"
                            :rules="editedItem.commentRules"
                            required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>

                  <v-data-table
                    v-model="editedItem.selected"
                    :headers="headers_modal"
                    :items="editedItem.selected"
                    dense
                    sort-by="id"
                    sort-desc
                    class="elevation-1"
                    :loading="loading"
                    loading-text="Consultando datos... Espere por favor"
                    show-select
                    item-key="coddoc"
                    checkbox-color="error"
                  >

                  <template v-slot:[`item.calc_ret`]="{ item }">
                      <div>
                        {{ numberWithCommas((item.precio * item.cant).toFixed(2)) }}
                      </div>
                  </template>

                  <template slot="body.append">
                    <tr class="gray--text">
                      <!-- <th class="title"></th> -->
                      
                      <th colspan="8" class="totales">
                      <v-row class="pt-2">
                        <v-col cols="12" sm="12" md="12">
                          <v-row>
                            <v-col cols="12" sm="12" md="3">
                              Uva {{ sumUva(2).cant.toFixed(2) }} 
                              <v-icon small> fas fa-arrow-circle-right </v-icon> 
                              {{ sumUva(2).oro.toFixed(2) }}
                            </v-col>
                            <v-col cols="12" sm="12" md="3">
                              P. Húmedo {{ sumUva(3).cant.toFixed(2) }} 
                              <v-icon small> fas fa-arrow-circle-right </v-icon> 
                               {{ sumUva(3).oro.toFixed(2) }}
                            </v-col>
                            <v-col cols="12" sm="12" md="3">
                              P. Seco {{ sumUva(4).cant.toFixed(2) }} 
                              <v-icon small> fas fa-arrow-circle-right </v-icon> 
                               {{ sumUva(4).oro.toFixed(2) }}
                            </v-col>
                            <v-col cols="12" sm="12" md="3">

                              P. Mojado {{ sumUva(7).cant.toFixed(2) }} 
                              <v-icon small> fas fa-arrow-circle-right </v-icon> 
                               {{ sumUva(7).oro.toFixed(2) }}
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      </th>
                      
                    </tr>

                    <tr class="gray--text">
                      <!-- <th class="title"></th> -->
                      
                      <th colspan="8" class="totales">
                      <v-row class="pt-2">

                        <v-col cols="12" sm="12" md="4">
                          Total Oro: {{ numberWithCommas((parseFloat(sumUva(2).oro)  + parseFloat(sumUva(3).oro) + 
                             parseFloat(sumUva(4).oro)  + parseFloat(sumUva(7).oro)).toFixed(2))  }}
                        </v-col>
                      </v-row>
                      </th>
                      
                    </tr>
                  </template>
                  </v-data-table>

                </v-container>
              </v-card-text>

              <!-- <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
                <v-btn color="blue darken-1" text @click="save">Guardar</v-btn>
              </v-card-actions> -->

              <v-card-actions>
                <v-row align="center" class="d-flex mb-2 mx-4">
                  <v-col cols="12" sm="12" md="6">

                  </v-col>
                  <v-col cols="12" sm="12" md="3">

                    <v-btn
                      color="warning"
                      rounded
                      outlined
                      class="flex-grow-1"
                      block
                      large
                      @click="dialog = false"
                      :elevation="0"
                      :minHeight="56"
                      >Cancelar
                    </v-btn>

                  </v-col>

                  <v-col  cols="12" sm="12" md="3">

                    <v-btn
                      color="primary"
                      rounded
                      block
                      large
                      @click="save"
                      :elevation="0"
                      :minHeight="56"
                      >Guardar
                    </v-btn>

                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog 
          v-model="dialogFactor" 
          max-width="600px"
          >
            <!-- <template v-slot:activator="{ on }">
                <v-btn ref="boton2" color="primary" dark v-on="on">Nuevo Registro</v-btn>
            </template> -->
            <v-card>
              
              <v-card-title>
                <span class="headline">Factores de Conversión</span>
                <v-spacer></v-spacer>
                <div>

              <v-btn
                style="right:10px; top:10px;"
                icon
                color="error"
                fab
                absolute
                @click="dialogFactor = false"
              >
                <v-icon dark>fa-times</v-icon>
              </v-btn>

                </div>
              </v-card-title>

              <v-card-text>
                <v-container>

                  <v-data-table
                    :headers="headers_types"
                    :items="types"
                    sort-by="nombre"
                    :loading="loading"
                    :hide-default-footer="true"
                    loading-text="Consultando datos... Espere por favor"
                  >
                  
                  <template v-slot:item.factor="props">
                    <v-edit-dialog
                      :return-value.sync="props.item.factor"
                      @save="saveFactor(props.item)"
                    >
                      {{ props.item.factor }}
                      <template v-slot:input>
                        <v-text-field
                          v-model="props.item.factor"
                          label="Editar"
                          single-line
                          type="number"
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>

                  </v-data-table>
                </v-container>
              </v-card-text>

              <!-- <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
                <v-btn color="blue darken-1" text @click="save">Guardar</v-btn>
              </v-card-actions> -->
            </v-card>
          </v-dialog>
      </template>

      <template slot="body.append">
      <tr class="gray--text">
        <!-- <th class="title"></th> -->
        
        <th colspan="8" class="title">
        <v-row class="pt-2">
          <v-col cols="12" sm="12" md="4">
            Total Oro: {{ numberWithCommas((parseFloat(sumField('total_ret'))).toFixed(2)) }}
          </v-col>
        </v-row>
        </th>
        
      </tr>
    </template>
    </v-data-table>
  </v-card>
</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import axios from "axios";
import * as moment from 'moment';
import jsPDF from 'jspdf';
import n2wordsES from 'n2words/lib/i18n/ES.mjs'

export default {
  name: "calculate",
  data: () => ({
    dialog: false,
    dialogFactor: false,
    samplings: [],
    currentItems: [],
    items: [],
    company: {},
    customers: [],
    productores: [],
    types: [],
    damages: [],
    processes: [],
    damages: [],
    process: "",
    type: "",
    id_customer: null,
    dates: [],
    modal: false,
    loader: null,
    loading3: false,
    dialogDelete: false,
    search: "",
    loading: false,
    titulo: "Cálculo de Retenciones",
    headers: [
      { text: "Fecha", align: "left", value: "fecha"},
      { text: "Nombre", align: "left", value: "nomprov" },
      { text: "#Factura", value: "bill" },
      { text: "Nota de Peso", value: "coddoc" },
      { text: "Clase de Café", value: "product" },
      { text: "Cantidad", value: "cant" },
      { text: "Precio", value: "precio" },
      { text: "Retención", value: "calc_ret" }
    ],
    headers_modal: [
      { text: "Fecha", align: "left", value: "fecha", sortable: false},
      { text: "Nombre", align: "left", value: "nomprov" },
      { text: "Nota de Peso", value: "coddoc" },
      { text: "Clase de Café", value: "product" },
      { text: "Cantidad", value: "cant" },
      { text: "Precio", value: "precio" },
      { text: "SubTotal", value: "calc_ret" }
    ],
    headers_types: [
      { text: "Clase de Café", align: "left", value: "nombre" },
      { text: "Factor (100qq)", value: "factor", sortable: false },
    ],
    editedIndex: -1,

    valid: true,
    

    editedItem: {
      productor: null,
      invalid: false,
      comment: "",
      selected: [],
      id_productorRules: [v => !!v || "Escoge un Productor"],
      commentRules: [
                      v => !!v || "Escribe el número de comprobante de compra o recibo de pago",
                      v => (v.length == 7 && v > 0) || 'El número debe tener 7 digitos'
                    ],
    },
    defaultItem: {
      productor: null,
      invalid: false,
      comment: "",
      selected: [],
      id_productorRules: [v => !!v || "Escoge un Productor"],
      commentRules: [
                      v => !!v || "Escribe el número de comprobante de compra o recibo de pago",
                      v => (v.length == 7 && v > 0) || 'El número debe tener 7 digitos'
                    ],
    },
  }),

  computed: {
    
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Registro" : "Editar Registro";
    },
    ...mapState(["db","headers_db"]),
    dateRangeText: {
        get()
        {
            return this.dates.join(' ~ ')
        },
        set()
        {
            this.dates = []
        }
    },
  },

  watch: {
    // dialog(val) {
    //   val || this.close();
    // },
    loader () {
        const l = this.loader
        this[l] = !this[l]

        setTimeout(() => (this[l] = false), 1000)

        this.loader = null
    },
  },

  methods: {
    ...mapActions(["validateSession"]),
    // Metodos base de datos
    async getSamplings(){
      await axios.get(this.db + 'retentions',
        {
          headers: this.headers_db
        }).then(response => {
          let datos2 = response.data.data;
          datos2.forEach(element => {
            element.total_ret = this.calc_ret(element.cant, element.id_coffee)
          });
          this.samplings = this.currentItems = datos2;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.validateSession(error).then((resp) => { if(resp) this.getSamplings(); })
        });
    },

    async getCustomers(){
      await axios.get(this.db + 'providers_info',
        {
          headers: this.headers_db
        }).then(response => {
          this.customers = response.data.data;
          this.loading = false;
          console.log(this.customers);
        })
        .catch((error) => {
          this.loading = false;
          this.validateSession(error).then((resp) => { if(resp) this.getCustomers(); })
        });
    },
    
    // async getProviders(){
    //   await axios.get(this.db + 'retention/providers',
    //     {
    //       headers: this.headers_db
    //     }).then(response => {
    //       this.productores = response.data.data;
    //       this.loading = false;
    //     })
    //     .catch((error) => {
    //       this.loading = false;
    //       this.validateSession(error).then((resp) => { if(resp) this.getProviders(); })
    //     });
    // },

    async getTypes(){
      await axios.get(this.db + 'types',
        {
          headers: this.headers_db
        }).then(response => {
          this.types = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.validateSession(error).then((resp) => { if(resp) this.getTypes(); })
        });
    },

    async getCompany(){
      await axios.get(this.db + 'company',
        {
          headers: this.headers_db
        }).then(response => {
          console.log(response.data.data);
          this.company = response.data.data;
        })
        .catch((error) => {
          this.validateSession(error).then((resp) => { if(resp) this.getCompany(); })
        });
    },
    // FIN Metodos base de datos

    // Metodos Generales
    dateFormat(date) {
      return moment(date).format('DD/MM/YYYY hh:mm a');
    },
    
    sumField(key) {
      // console.log("key",key);
      // sum data in give key (property)
      return this.currentItems.reduce((a, b) => parseFloat(a) + (parseFloat(b[key]) || 0), 0);
    },

    sumUva(type) {
      if (this.editedItem.selected == undefined || this.editedItem.selected.length == 0) {
        return {
          cant: 0,
          oro: 0
        };
      }
      
      let uva = 0;
      this.editedItem.selected.forEach(item => {
        if (item.id_coffee == type) {
          uva += parseFloat(item.cant);
        }
      });

      let element = this.types.filter(doc => {
        return doc.codigo == type;
      });

      if (!element.length > 0) {
        return;
      }
        // console.log("uva" + type, uva);
      let factor  = (element[0].factor / 100);
      return {
        cant: uva,
        oro: (uva * parseFloat(factor))
      }
    },

    calc_ret(cant, type) {

      let element = this.types.filter(doc => {
        return doc.codigo == type;
      });

      if (!element.length > 0) {
        return;
      }
      let factor  = (element[0].factor / 100);
      return (parseFloat(cant) * parseFloat(factor)).toFixed(2)
    },
    showModal() {
      this.dialog = true;
      console.log(this.editedItem.selected, this.dialog);
    },
    getByCustomer() {
      console.log(this.id_customer, this.samplings);
      if (this.id_customer) {
        this.currentItems = this.samplings.filter(doc => {
          return doc.codprov == this.id_customer;
        });
      }
      else {
        this.currentItems = this.samplings;
      }
    },
    
    getSettings() {
      // this.loader = 'loading3'
      this.getTypes();
      this.dialogFactor = true;
    },

    close() {
      this.dialog = false;
      this.dialogFactor = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      }, 300);
    },
    save() {
      console.log(this.editedItem);
      if (this.$refs.form.validate()) {
        let selected = this.editedItem.selected;
        selected.forEach(element => {
          element.quantity = this.calc_ret(element.cant, element.id_coffee);
        });

        axios.post(this.db + 'retentions/add', {
          receipt: this.editedItem.comment,
          invalid: this.editedItem.invalid ? 1 : 0,
          id_provider: this.editedItem.productor.codigo,
          // id_provider: this.editedItem.productor.id,
          ids_cxp: selected
        },
        {
          headers: this.headers_db
        })
        .then((response) => {
          
          this.generatePdf(this.editedItem);

          console.log(response.data.message);
          this.$store.dispatch("setSnackbar", {
            text: response.data.message,
          });
          // this.getSamplings();
          selected.forEach(element => {
            this.samplings = this.samplings.filter(doc => {
              return doc.id_cxp != element.id_cxp
            })
          });
          this.currentItems = this.samplings;
          this.close();
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("setSnackbar", {
            text: error,
          });
        });
      }
    },

    async generatePdf(item) {
        console.log('ITEM',item);
        let selected = item.selected;

        let cash = 0;
        selected.forEach(element => {
          console.log(element.valor);
          // item.precio * item.cant)
          cash += parseFloat(element.precio) * parseFloat(element.cant);
        });

        let total_cash = cash.toFixed(2);
        
        let total_ret = this.numberWithCommas((parseFloat(this.sumUva(2).oro)  + parseFloat(this.sumUva(3).oro) + 
        parseFloat(this.sumUva(4).oro)  + parseFloat(this.sumUva(7).oro)).toFixed(2));
        
        // let total_cash = (parseFloat(price_oro) * parseFloat(total_ret)).toFixed(2);

        const split_ret = total_ret.split(".");
        const qq = n2wordsES(split_ret[0]).toUpperCase();
        const lb = n2wordsES(split_ret[1]).toUpperCase();

        const split_cash = total_cash.split(".");
        const lps = n2wordsES(split_cash[0]).toUpperCase();
        const cent = n2wordsES(split_cash[1]).toUpperCase();

        let ret_text = qq + " QQS. CON " + lb + " LIBRAS ORO";
        let cash_text = lps + " LPS. CON " + cent + " CENTAVOS";
        
        moment.locale('es');
        let place_provider = item.productor.desbop;
        let day = moment().format("DD");
        let month = moment().format("MMMM").toUpperCase();
        let year = moment().format("YYYY");
        let provider = (item.productor.nombre).toUpperCase();
        let rtn = item.productor.rtn;
        let clave = item.productor.codihc;

        let place_owner = this.company.dire1;
        let owner = this.company.name2;
        let rtn_owner = this.company.rtn;
        let clave_owner = this.company.clave_owner;
        let operation_key = this.company.operation_key;
        
        var doc = new jsPDF();
        // doc.setFont("helvetica", "bold");
        doc.setFontSize(12)
        let beginY = 45;
        
        doc.text(place_owner, 53, beginY);

        beginY += 9;
        doc.text(day, 80, beginY);
        doc.text(month, 118, beginY, null, null, "center");
        doc.text(year, 164, beginY, null, null, "center");

        beginY += 7;
        doc.text(owner, 80, beginY);

        beginY += 8;
        doc.text(rtn_owner, 30, beginY);
        doc.text(clave_owner, 120, beginY);
        
        beginY += 8;
        doc.text(provider, 86, beginY);
        
        beginY += 8;
        doc.text(rtn, 30, beginY);
        doc.text(clave, 120, beginY);
        
        beginY += 8;
        doc.text(place_provider, 75, beginY);
        
        beginY += 8;
        doc.text(item.productor.desmunp, 35, beginY);
        doc.text(item.productor.desdepp, 130, beginY);
        
        beginY += 8;
        doc.text(item.productor.telprin, 35, beginY);
        doc.text(item.productor.desbof, 130, beginY);
        
        beginY += 9;
        doc.text(item.productor.desmunf, 35, beginY);
        doc.text(item.productor.desdepf, 130, beginY);
        
        beginY += 19;
        doc.text(split_ret[0] + ".", 19, beginY);
        doc.text(split_ret[1], 42, beginY);
        doc.text(ret_text, 57, beginY);
        
        beginY += 18;
        doc.text(this.numberWithCommas(split_cash[0]) + ".", 15, beginY);
        doc.text(this.numberWithCommas(split_cash[1]), 42, beginY);

        if(cash_text.length > 50){
          let long = cash_text.length;
          let first_line = cash_text.substring(0, 50);
          let last_line = cash_text.substring(50, long);
          doc.text(first_line, 57, beginY - 2);
          doc.text(last_line, 57, beginY + 5);
        }
        else {
          doc.text(cash_text, 57, beginY - 1);
        }
        // doc.text("OCHO MIL CIENTO DIECIOCHO CON CERO CENTAVOS", 60, 158);
        beginY += 17;
        doc.text(operation_key, 15, beginY);

        doc.autoPrint();
        const blob = doc.output("bloburl");
        window.open(blob);
    },

    // PURINGLA
    // async generatePdf(item) {
    //     console.log('ITEM',item);
    //     let selected = item.selected;

    //     let cash = 0;
    //     selected.forEach(element => {
    //       console.log(element.valor);
    //       // cash += parseFloat((element.valor));
    //       cash += parseFloat(element.precio) * parseFloat(element.cant);
    //     });

    //     let total_cash = cash.toFixed(2);
        
    //     let total_ret = this.numberWithCommas((parseFloat(this.sumUva(2).oro)  + parseFloat(this.sumUva(3).oro) + 
    //                          parseFloat(this.sumUva(4).oro)  + parseFloat(this.sumUva(7).oro)).toFixed(2));

    //     let ret_text = n2wordsES(total_ret).toUpperCase();
    //     let cash_text = n2wordsES(total_cash).toUpperCase();
        
    //     console.log(total_ret, total_cash);
        
    //     moment.locale('es');
    //     let place_provider = item.productor.desbop;
    //     let day = moment().format("DD");
    //     let month = moment().format("MMMM").toUpperCase();
    //     let year = moment().format("YYYY");
    //     let provider = (item.productor.nombre).toUpperCase();
    //     let rtn = item.productor.rtn;
    //     let clave = item.productor.codihc;

    //     let place_owner = this.company.dire1;
    //     let owner = this.company.name2;
    //     let rtn_owner = this.company.rtn;
    //     let clave_owner = this.company.clave_owner;
    //     let operation_key = this.company.operation_key;
        
    //     var doc = new jsPDF();
    //     // doc.setFont("helvetica", "bold");
    //     doc.setFontSize(12)
        
        
    //     doc.text(place_owner, 53, 51);

    //     doc.text(day, 80, 60);
    //     doc.text(month, 118, 60, null, null, "center");
    //     doc.text(year, 164, 60, null, null, "center");

    //     doc.text(owner, 80, 68);

    //     doc.text(rtn_owner, 30, 77);
    //     doc.text(clave_owner, 120, 77);

    //     doc.text(provider, 86, 85);
        
    //     doc.text(rtn ? rtn : "", 30, 94);
    //     doc.text(clave ? clave : "", 120, 94);

    //     doc.text(place_provider, 75, 102);

    //     doc.text(item.productor.desmunp, 40, 110);
    //     doc.text(item.productor.desdepp, 130, 110);
        
    //     doc.text(item.productor.telprin ? item.productor.telprin : "", 35, 119);
    //     doc.text(item.productor.desbof, 130, 119);

    //     doc.text(item.productor.desmunf, 40, 127);
    //     doc.text(item.productor.desdepf, 130, 127);

    //     doc.text(total_ret, 20, 147);
    //     doc.text(ret_text, 60, 147);

    //     doc.text(this.numberWithCommas(total_cash), 20, 166);

    //     if(cash_text.length > 50){
    //       let long = cash_text.length;
    //       let first_line = cash_text.substring(0, 50);
    //       let last_line = cash_text.substring(50, long);
    //       doc.text(first_line, 60, 164);
    //       doc.text(last_line, 60, 170);
    //     }
    //     else {
    //       doc.text(cash_text, 60, 164);
    //     }
    //     // doc.text("OCHO MIL CIENTO DIECIOCHO CON CERO CENTAVOS", 60, 158);

    //     doc.text(operation_key, 15, 184);

    //     doc.autoPrint();
    //     const blob = doc.output("bloburl");
    //     window.open(blob);
    // },

    saveFactor(payload) {
      console.log(payload.factor);
      if (!parseFloat(payload.factor) > 0) {
        return;
      }
      axios.post(this.db + 'factor/update', {
        codigo: payload.codigo,
        factor: payload.factor,
      },
      {
        headers: this.headers_db
      })
      .then((response) => {
        console.log(response.data.message);
        this.$store.dispatch("setSnackbar", {
          text: response.data.message,
        });
        //this.getSamplings();
      })
      .catch((error) => {
        console.log(error);
        this.$store.dispatch("setSnackbar", {
          text: error,
        });
      });

    },

    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

  async getAll() {
      this.loader = 'loading3'
      this.loading = true;
      await this.getSamplings();
      this.getCustomers();
    },
  },
  async mounted() {
    this.loading = true;
    await this.getSamplings();
    await this.getCustomers();
    await this.getTypes();
    // this.getProviders();
    this.getCompany();
  },
  // filters: {
  //   formatNumber: function(value) {
  //     if (!value) return "";
  //     return new Intl.NumberFormat().format(value);
  //   },
  // },
};
</script>

<style lang="scss">
  .totales {
    font-size: medium !important;
  }
</style>
